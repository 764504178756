@font-face {
  font-family: DMBold;
  src: url("../assets/fonts/DMSans-Bold.ttf");
}

@font-face {
  font-family: DMMedium;
  src: url("../assets/fonts/DMSans-Medium.ttf");
}

body {
  height: 100vh;
  margin: 0px;
  background: var(--backgroundColor);
}

#root {
  height: 100vh;
}

* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--fontColor);
  font-family: DMBold;
  letter-spacing: 1px;
}

p {
  color: var(--fontColor);
  font-family: DMMedium;
}
