@import "../../styles/variables.css";
:root {
  --navHeight: 5.5rem;
  --footerHeight: 4rem;
  --editorGrey: rgb(39, 39, 39);
}

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  /* overflow: hidden; */
  /* min-width: 1200px; */
  min-height: 900px;
}

.canvasContainer {
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  opacity: 0.4;
}

.orbPosition {
  height: 100%;
  width: 100%;
  margin-left: 0%;
}

.nav {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: var(--navHeight);
  border-bottom: var(--border);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 2rem;
  backdrop-filter: blur(25px);
  z-index: 2;
}

.nav h3 {
  font-size: 1.5rem;
}

.main {
  position: absolute;
  left: 0;
  top: var(--navHeight);
  width: 100%;
  height: calc(100% - var(--navHeight));
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* overflow: hidden; */
}

.section {
  width: 50%;
  height: 100%;
}

.leftSection {
  z-index: 2;
  width: 50%;
  height: 100%;
  padding: 0 5% 0 7%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.leftSection h3 {
  font-size: 2.25rem;
  line-height: 1.5em;
  margin-bottom: 3rem;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.formInputs {
  text-decoration: underline;
  color: var(--fontColor);
  cursor: pointer;
}

.infoIcon {
  height: 1.35rem;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}

.rightSection {
  display: flex;
  padding-top: var(--navHeight);
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10rem;
  align-items: center;
  overflow-y: scroll;
}

.logoContainer {
  height: var(--footerHeight);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
}

.logos {
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
}

.purusha {
  height: 2.5rem;
  margin-right: 1rem;
}

.meta {
  height: 4rem;
  margin-right: 0.5rem;
}

.instagram {
  height: 1.75rem;
  margin-right: 1.75rem;
}

.whatsapp {
  height: 1.75rem;
  margin-right: 1.75rem;
}

.messenger {
  height: 1.75rem;
}

.responseUser {
  width: 75%;
  max-width: 650px;
  display: flex;
  font-size: 1.5rem;
  z-index: 1;
  padding-top: 2rem;
  padding: 0 5% 0 7%;
  justify-content: right;
  align-items: right;

}

.responseAi {
  width: 75%;
  max-width: 650px;
  font-size: 1.5rem;
  z-index: 1;
  margin-top: auto;
  padding-top: 2rem;
  padding: 0 5% 0 7%;
  display: flex;
  justify-content: left;
  align-items: left;
}

.inputContainer {
  z-index: 1;
  width: 75%;
  margin-top: 2rem;
  padding: 0 5% 0 7%;
  color: var(--color);
}

.input {
  border-radius: var(--borderRadius);
  padding: 1.5rem 2.5rem;
  border: none;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(30px);
  width: 100%;
  color: var(--color);
  font-family: DMBold;
  resize: none;
}

.input:focus {
  outline: none;
}

.input,
.input::placeholder {
  font-size: 1.125rem;
  color: var(--fontColor);
}
